




import { Component, Vue } from 'vue-property-decorator'

@Component
export default class WaitingPage extends Vue {
  created() {
    setTimeout(() => {
      let token = ''
      if (sessionStorage.getItem('vuex')) {
        const a: string = sessionStorage.getItem('vuex') as string
        token = JSON.parse(a).auth.token
      }
      if (token) {
        window.location.replace(
          this.$route.query.redirect ? String(this.$route.query.redirect) : '/'
        )
      } else {
        this.$router.push({
          name: 'login',
          query: this.$route.query
        })
      }
    }, 300)
  }
}
